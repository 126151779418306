export default function useRoundToDecimalPlaces() {

  const roundToDecimalPlaces = (number, decimalPlaces = 2) => {
    const multiplier = 10 ** decimalPlaces;
    const rounded = Math.round(number * multiplier) / multiplier; // Afronden naar boven

    return rounded;
  }

  return {
    roundToDecimalPlaces
  }

}