import useRoundToDecimalPlaces from "@/composables/v3/components/helpers/useRoundToDecimalPlaces";
import { useI18n } from "vue-i18n";


export default function useCharts() {

  const { roundToDecimalPlaces } = useRoundToDecimalPlaces();
  const { t } = useI18n();

  function generateChartData(hitsByMonth) {

    // Define the categories (months)
    const categories = hitsByMonth.items.map(hit => {
      // TODO make an short and long description.
      return hit.label; // Assuming hit.label contains the desired values
    });

    const revenueData = hitsByMonth.items.map(hit => {
      return hit.totals.revenue
    })

    const hitsData = hitsByMonth.items.map(hit => {
      return hit.totals.hits
    })

    // Construct the xavis object
    const xavis = { categories };

    // Construct the series object
    const series = [{ name: hitsByMonth.label, hits: hitsData, data: revenueData }];

    return { xavis, series };
  }

  function getDateRangeTimestamps(period, time) {

    let startDate, endDate;

    if (period === 'years') {
      // Parse the provided year to create start and end dates
      startDate = new Date(time.year, 0, 1); // January 1st of the provided year
      endDate = new Date(time.year, 11, 31); // December 31st of the provided year
    } else if (period === 'months') {
      const month = time.month; // Extract the month number from the provided object
      const year = time.year; // Extract the year from the provided object

      if (month >= 1 && month <= 12 && year !== undefined) {
        // If a valid month number and year are provided
        startDate = new Date(year, month - 1, 1); // 1st day of the provided month
        endDate = new Date(year, month, 0); // Last day of the provided month
      } else {
        // If an invalid month number or year is provided
        throw new Error('Invalid month number or year');
      }
    } else if (period === 'custom') {
      if (time.custom.start && time.custom.end) {
        startDate = new Date(time.custom.start);
        endDate = new Date(time.custom.end);
      } else {
        throw new Error('Start and end dates are required for custom period');
      }
    }

    // Set the time of the end date to 23:59:59.999
    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate.setSeconds(59);
    endDate.setMilliseconds(999);

    // Convert the dates to timestamps
    const startTimeStamp = startDate.getTime() / 1000; // Convert milliseconds to seconds
    const endTimeStamp = endDate.getTime() / 1000; // Convert milliseconds to seconds

    return [startTimeStamp, endTimeStamp];
  }

  function getMonthName(monthNumber) {
    // Arrays of month names
    const months = [
      { short: 'jan', long: 'january' },
      { short: 'feb', long: 'february' },
      { short: 'mar', long: 'march' },
      { short: 'apr', long: 'april' },
      { short: 'may', long: 'may' },
      { short: 'jun', long: 'june' },
      { short: 'jul', long: 'july' },
      { short: 'aug', long: 'august' },
      { short: 'sep', long: 'september' },
      { short: 'oct', long: 'october' },
      { short: 'nov', long: 'november' },
      { short: 'dec', long: 'december' }
    ];
  
    // // Check if the input number is valid
    // if (monthNumber < 1 || monthNumber > 12) {
    //   return "Invalid month number";
    // }
  
    // Get the month index
    const monthIndex = monthNumber - 1;
  
    // Return the month names
    return {
      short: t(`titles.${months[monthIndex].short}`),
      long: t(`titles.${months[monthIndex].long}`)
    };
  }

  function getDaysInMonth(monthNumber, year) {
    // Get the numerical value of the month from the parameter
    const month = monthNumber;

    // Return the number of days in the specified month
    return new Date(year, month, 0).getDate();
  }

  function convertYearToLabel(year) {
    // Extract last two digits
    const lastTwoDigits = year.toString().slice(-2);

    // Return as label
    return "'" + lastTwoDigits;
  }

  // function sortHitsByMonth(hits, chartConfig) {
  //   // Initialize an object to store hits by month
  //   const hitsByMonth = { label: chartConfig.label, items: [] }

  //   // Initialize hitsByMonth with all months
  //   for (let month = 1; month <= 12; month++) {
  //     hitsByMonth.items.push({
  //       month: month,
  //       label: getMonthName(month, chartConfig.time),
  //       items: [],
  //       totals: {
  //         revenue: 0,
  //         hits: 0 // Add other totals if needed
  //       }
  //     });
  //   }

  //   // Iterate through each hit
  //   hits.forEach(hit => {
  //     // Extract the month from the created_at field
  //     const month = new Date(hit.sale_date).getMonth() + 1; // Months are zero-based, so add 1

  //     // Push the hit to the corresponding month's items array
  //     const findMonth = hitsByMonth.items.find(m => m.month === month);

  //     findMonth.items.push(hit)

  //     // Increment hits count for the month
  //     findMonth.totals.hits++;

  //     const revenue = parseFloat(hit.totals.net_total_with_vat);

  //     // Calculate and accumulate revenue for the month
  //     findMonth.totals.revenue += roundToDecimalPlaces(revenue);

  //     findMonth.totals.revenue = parseFloat(findMonth.totals.revenue.toFixed(2));

  //     // const totals = findMonth.totals.revenue += roundToDecimalPlaces(revenue);
  //     // findMonth.totals.revenue = totals.toFixed(2);
  //     // console.log(totals)
  //   });

  //   return hitsByMonth;
  // }

  // function sortHitsByDay(hits, chartConfig) {
  //   // Initialize an object to store hits by day
  //   const hitsByDay = { label: chartConfig.label, items: [] };

  //   // Initialize hitsByDay with all days of the month
  //   const daysInMonth = getDaysInMonth(chartConfig.time.month, chartConfig.time.year);
  //   for (let day = 1; day <= daysInMonth; day++) {
  //     hitsByDay.items.push({
  //       day: day,
  //       label: day + ' ' + getMonthName(chartConfig.time.month, chartConfig.time),
  //       items: [],
  //       totals: {
  //         revenue: 0,
  //         hits: 0 // Add other totals if needed
  //         // Add other totals if needed
  //       }
  //     });
  //   }

  //   // Iterate through each hit
  //   hits.forEach(hit => {
  //     // Extract the day from the sale_date field
  //     const day = new Date(hit.sale_date).getDate();

  //     // Push the hit to the corresponding day's items array
  //     const findDay = hitsByDay.items.find(d => d.day === day);
  //     findDay.items.push(hit);

  //     findDay.totals.hits++;

  //     const revenue = parseFloat(hit.totals.net_total_with_vat);

  //     // Calculate and accumulate revenue for the day
  //     findDay.totals.revenue += roundToDecimalPlaces(revenue);
  //     findDay.totals.revenue = parseFloat(findDay.totals.revenue.toFixed(2));
  //   });

  //   return hitsByDay;
  // }

  // function sortHitsByRange(hits, chartConfig) {
  //   // Initialize an object to store hits by range
  //   const hitsByRange = { label: chartConfig.label, items: [] as any };

  //   // Convert start and end dates to Date objects
  //   const start = new Date(chartConfig.time.custom.start);
  //   const end = new Date(chartConfig.time.custom.end);

  //   // Iterate through each date in the range
  //   for (let currentDate = new Date(start); currentDate <= end; currentDate = new Date(currentDate.getTime() + 86400000 /* milliseconds in a day */)) {
  //     const day = currentDate.getDate();
  //     const label = `${day} ${getMonthName(currentDate.getMonth() + 1, chartConfig.time)}`;

  //     hitsByRange.items.push({
  //       day: day,
  //       label: label,
  //       items: [],
  //       totals: {
  //         revenue: 0,
  //         hits: 0
  //         // Add other totals if needed
  //       }
  //     });
  //   }

  //   // Iterate through each hit
  //   hits.forEach(hit => {


  //     // Extract the day from the sale_date field
  //     const hitDate = new Date(hit.sale_date);

  //     // Check if hitDate is within the specified range
  //     if (hitDate >= start && hitDate <= end) {
  //       const day = hitDate.getDate();
  //       const findDay = hitsByRange.items.find(d => d.day === day);
  //       findDay.items.push(hit);
  //       findDay.totals.hits++;
  //       const revenue = parseFloat(hit.totals.net_total_with_vat);

  //       // Calculate and accumulate revenue for the day
  //       findDay.totals.revenue += roundToDecimalPlaces(revenue);
  //       findDay.totals.revenue = parseFloat(findDay.totals.revenue.toFixed(2));
  //     }
  //   });

  //   return hitsByRange;
  // }
  function groupHitsByTimeUnit(hits: any[], chartConfig: any, timeUnit: any) {
    const groupBy: { [key: number]: any } = {}; // Object to store hits grouped by time unit

    let total: number;
    let startDate: Date = new Date();
    let endDate: Date;

    switch (timeUnit.type) {
      case 'month':
        total = 12;
        break;
      case 'day':
        total = getDaysInMonth(chartConfig.time.month, chartConfig.time.year);
        break;
      case 'range':
        startDate = new Date(chartConfig.time.custom.start);
        endDate = new Date(chartConfig.time.custom.end);
        total = Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24)) + 1; // total days in the range
        break;
      default:
        throw new Error('Invalid time unit');
    }

    for (let i = 1; i <= total; i++) {
      let label: any;
      if (timeUnit.type === 'month') {
        const monthName = getMonthName(i);
        label = { short: monthName.short, long: monthName.long };
      } else if (timeUnit.type === 'day') {
        const monthName = getMonthName(chartConfig.time.month);
        label = { short: `${i} ${monthName.short}`, long: `${i} ${monthName.long}` };
      } else {
        const currentDate = new Date(startDate);
        const monthName = getMonthName(currentDate.getMonth() + 1)
        currentDate.setDate(currentDate.getDate() + (i - 1));
        label = { short: `${currentDate.getDate()} ${monthName.short}`, long: `${currentDate.getDate()} ${monthName.long}` };
      }

      groupBy[i] = {
        label: label,
        items: [],
        totals: {
          revenue: 0,
          hits: 0
        }
      };
    }

    // Group hits by the specified time unit
    hits.forEach(hit => {
      const saleDate = new Date(hit.sale_date);
      let unit;
      if (timeUnit.type === 'range') {
        unit = new Date(hit.sale_date).getDate();
      } else if (timeUnit.type === 'month') {
        unit = saleDate.getMonth() + 1; // Months are 0-based in JavaScript Date
      } else {
        unit = saleDate.getDate();
      }
  
      const unitItem = groupBy[unit];
      if (unitItem) {
        unitItem.items.push(hit);
        unitItem.totals.hits++;
        const revenue = parseFloat(hit.totals.net_total_with_vat);
        unitItem.totals.revenue += roundToDecimalPlaces(revenue);
      }
    });
  

    // Round revenue and return the grouped hits
    for (const key in groupBy) {
      groupBy[key].totals.revenue = parseFloat(groupBy[key].totals.revenue.toFixed(2));
    }

    return { label: chartConfig.label, items: Object.values(groupBy) };
  }

  function calculateTotals(chartConfig, totalHits) {
    const totals = chartConfig.series.map(series => {
      return series.data.reduce((acc, val) => acc + val, 0);
    });

    const roundedTotals = roundToDecimalPlaces(totals);

    return { value: roundedTotals, hits: totalHits };
  }

  function sortHits(period, hits, chartConfig) {
    // const hitsByMonth = groupHitsByTimeUnit(hits, chartConfig, { type: 'month', total: 12, getter: 'getMonth' });
    // const hitsByDay = groupHitsByTimeUnit(hits, chartConfig, { type: 'day', total: getDaysInMonth(chartConfig.time.month, chartConfig.time.year), getter: 'getDate' });
    // const hitsByRange = groupHitsByTimeUnit(hits, chartConfig, { type: 'range', getter: 'getDate' });
    let hitsSorted;

    if (period === 'years') {
      hitsSorted = groupHitsByTimeUnit(hits, chartConfig, { type: 'month', total: 12, getter: 'getMonth' });
    } else if (period === 'months') {
      hitsSorted = groupHitsByTimeUnit(hits, chartConfig, { type: 'day', total: getDaysInMonth(chartConfig.time.month, chartConfig.time.year), getter: 'getDate' });
    } else if (period === 'days') {
      // hitsSorted = sortHitsByDay(hits, chartConfig);
    } else if (period === 'custom') {
      hitsSorted = groupHitsByTimeUnit(hits, chartConfig, { type: 'range', getter: 'getDate' });
    } else {
      // Handle invalid period
      throw new Error('Invalid period provided');
    }

    return {
      data: hitsSorted,
      totals: {
        hits: hits.length
      }
    }
  }


  return {
    generateChartData,
    getDateRangeTimestamps,
    getMonthName,
    getDaysInMonth,
    convertYearToLabel,
    // sortHitsByMonth,
    // sortHitsByDay,
    // sortHitsByRange,
    calculateTotals,
    sortHits
  }

};